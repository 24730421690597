import { React, banner1, useSelector, Slider, NavLink } from "../../imports/generalImport";
import { Container, Skeleton, Box } from "../../imports/muiImport";
import './homeslider.css'

export default function Index({ height = 181 }) {
    const sliderData = useSelector((state) => state.SlidersData.allData);
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
    };

    return (
        <Container
            maxWidth="xl"
            disableGutters={true}
            className="slider-container"
            sx={{
                // backgroundColor: { xs: "white.main", sm: 'backgroundPrimary.main' },
                // background: { xs: "rgb(241,245,246)", sm: 'backgroundPrimary.main' },
                // background: { xs: 'linear-gradient(0deg, rgba(241,245,246,0.7681197478991597) 0%, rgba(241,245,246,1) 100%)', sm: 'transparent' },
                // minHeight: {
                //     xs: `calc(${height}px + 40px)`,
                //     sm: '260px',
                //     md: '320px',
                //     lg: '390px',
                //     xl: "380px"
                // },
                // maxHeight: {
                //     xs: '20vh',
                //     sm: '260px',
                //     md: '320px',
                //     lg: '390px',
                //     xl: "53vh"
                // },
                position: 'relative',
                // overflow: 'hidden',
                // borderRadius: { xs: 4, sm: 0 }
            }}
        >
            {/* Gradient shadow overlay */}
            {/* <Box
                sx={{
                    position: 'absolute',
                    bottom: -30,
                    left: 0,
                    right: 0,
                    height: '122px', // Height of the gradient shadow
                    background: 'linear-gradient(to top, rgb(241 245 246), rgb(254 254 254 / 0%))',
                    filter: 'blur(8px)', // Apply a blur effect
                    zIndex: { xs: 0, sm: 1 },  // Ensure the shadow is above the background but below other elements
                    pointerEvents: 'none',  // Prevents the shadow from capturing mouse events
                }}
            /> */}

            {/* Slider content */}
            {sliderData?.length ?
                (<Slider {...settings} arrows={false}>
                    {sliderData?.map((item) => (
                        item?.url ?
                            (<NavLink to={item?.url} key={`hero-slider-${item.url}`}>
                                <SliderImage item={item} height={height} />
                            </NavLink>)
                            :
                            (<SliderImage item={item} height={height} key={`hero-slider-${item.url}`} />)
                    ))}
                </Slider>)
                :
                (<Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    sx={{
                        minHeight: {
                            xs: height,
                            sm: '260px',
                            md: '320px',
                            lg: '390px',
                            xl: "380px"
                        },
                        maxHeight: {
                            xs: height,
                            sm: '260px',
                            md: '320px',
                            lg: '390px',
                            xl: "53vh"
                        },
                    }}
                />)
            }
        </Container>
    );
}


function SliderImage({ item, height }) {
    return (
        <Box
            component="img"
            src={item.image || banner1}
            alt={`${item?.title}-99gift-hero-sliders`}
            loading="lazy"
            sx={{
                backgroundColor: { xs: "white.main", sm: 'backgroundPrimary.main' },
                overflow: 'hidden',
                width: '100%',
                minHeight: {
                    xs: height,
                    sm: '260px',
                    md: '320px',
                    lg: '390px',
                    xl: "380px"
                },
                maxHeight: {
                    xs: height,
                    sm: '260px',
                    md: '320px',
                    lg: '390px',
                    xl: "53vh"
                },
                borderRadius: { xs: 4, sm: 0 },
            }}
        />
    )
}