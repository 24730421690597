import { createTheme } from "@mui/material";
import { styled } from '@mui/material/styles';


export const custom99GiftTheme = createTheme({
  palette: {
    primary: {
      main: "#002865",
    },
    primaryDark: {
      main: "#002255",
    },
    secondary: {
      main: "#00B8EE",
    },
    white: {
      main: "#ffffff",
      light: "#e3e3e3",
    },
    dark: {
      main: "#000000",
      main2: "#212529",
    },
    footer: {
      main: "#184282",
    },
    textPrimary: {
      main: "#454545",
      main1: "#505050",
    },
    backgroundPrimary: {
      main: "#F1F5F6",
    },
    christmasDay: {
      main: "#000312",
      
    },
    boxShadowPrimary: {
      main: "#808080",
    },
    borderPrimary: {
      main: "#dee2e6",
    },
    lightSecondary: {
      main: "#deebff",
    },
    apparel: {
      main: "#FFB533"
    },
    mbackground: {
      main: "#E2EEFF",
      bg2: "#e4f4f8",
    },
    background: {
      main1: "#00286514",
      main2: "#deebff",
    },
    borderColor: {
      main: "#deebff",
      main1: "#dee2e6",
      main2: "#00286514",
    }
  },
  typography: {
    fontFamily: "Celias , sans-serif",
    main: "Celias",
  },
});
