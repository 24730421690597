import React, { useMemo } from "react";
import Header from "./Header";
import FooterReserved from "./innerComponent/FooterReserved";
import { Box, Container, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import "./innerComponent/styles/navbarmenu.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LargeFooter from "./ProfileLayout/FOOTER/LargeFooter";
import ScrollToTop from "../components/other/ScrollToTop";
import ChristmasAnimation from "../components/christmasDayUIDesign/components/ChristmasAnimation";
import DesktopHeaderBell from "../components/christmasDayUIDesign/components/DesktopHeaderBell";

const Layout = ({ children }) => {
  const memoizedHeader = useMemo(() => <Header />, []);
  const memoizedFooter = useMemo(() => <LargeFooter />, []);
  const memoizedFooterReserved = useMemo(() => <FooterReserved />, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <ScrollToTop />
      <DesktopHeaderBell />
      <ChristmasAnimation>
        <Container
          maxWidth="false"
          disableGutters={true}
          sx={{
            maxWidth: "100%",
            alignItem: "flex-start",
            border: 0,
            backgroundColor: {
              xs: "white.main",
              sm: "backgroundPrimary.main",
            },
          }}
        >
          {memoizedHeader}
          <Container
            maxWidth="xl"
            sx={{
              minHeight: "40vh",
              backgroundColor: {
                xs: "white.main",
                sm: "backgroundPrimary.main",
              },
              alignItem: "center",
              position: "relative",

            }}
            disableGutters={true}
          >

            <Outlet>
              {children}
            </Outlet>

          </Container>
          {memoizedFooter}
          {/* {memoizedFooterReserved} */}
        </Container>
      </ChristmasAnimation>
    </React.Fragment>
  );
};

export default React.memo(Layout);
