import React from 'react';
import { Box } from '@mui/material';
import {
    christmasBGFooter3,
    christmasSantaRunningGif4,
} from '../../../imports/generalImport';
import "../styles/santarunning.module.css"

const ChristmasSantaRunningVideo = () => {
    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    // backgroundColor: 'dark.main',
                    backgroundColor: 'transparent',
                    // background:'radial-gradient(at center center, #072436 0%, #01131e 80%)',
                    bottom: 0,
                    height: 211,
                    maxHeight: 211,
                    zIndex: { xs: 0, md: 99 },
                    display:{xs:'none',md:'block'}
                }}
            >
                <Box
                    component={'img'}
                    src={christmasBGFooter3}
                    sx={{
                        width: '100%',
                        objectFit: 'cover',
                        backgroundColor: 'transparent',
                        // background: 'radial-gradient(at center center, #072436 0%, #01131e 80%)',
                        position: 'fixed',
                        bottom: '-0px',
                        zIndex: { xs: 0, md: 999 }
                    }}
                />

            </Box>



            <Box
                sx={{
                    position: 'fixed',
                    bottom: 56,
                    left: 0,
                    width: '100%',
                    height: '100px',
                    backgroundColor: 'transparent',
                    zIndex: 1000,
                    animation: 'moveLeftToRight 15s linear infinite',
                    '@keyframes moveLeftToRight': {
                        '0%': {
                            transform: 'translateX(-100%)'
                        },
                        '100%': {
                            transform: 'translateX(100%)'
                        }
                    },
                }}
            >


                <Box
                    component={'img'}
                    src={christmasSantaRunningGif4}
                    sx={{
                        width: {
                            xs: '100%',
                            md: '100%',
                        },
                        height: {
                            xs: '100%',
                            md: '90%',
                        },
                        objectFit: 'contain',
                        backgroundColor: 'transparent',
                        position: 'relative',
                        bottom: { xs: 0, md: -66 },
                    }}
                />

            </Box>
        </>
    );
};

export default ChristmasSantaRunningVideo;
