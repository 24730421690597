import React, { useEffect, useState } from 'react';
import { Box, Button } from "../../../../imports/muiImport";
import { NavLink, useDispatch, useNavigate, useSelector } from "../../../../imports/generalImport";
import { handleAmount } from '../../../../shared/extra';
import { productPurchaseApi } from '../customPaymentHook';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { fetchUserBalance } from '../../../../store/userBalance/action';
import { textTransform } from '@mui/system';
import { purchaseProduct } from '../../../../apiCalls/urls/urlFunction';
import { actionNotifier } from '../../../../components/tostify';

function UserPayButton({ isLoggedIn, ProductDiscount, walletPayment, purchaseLoading, setPurchaseLoading }) {
    const DENOMINATION_STORE_DATA = useSelector((state) => state.Denominations?.allData);
    const PRODUCT_DETAIL = useSelector((state) => state.ProductDetail?.allData);
    const USER_PROFILE = useSelector((state) => state.UserProfile?.allData);
    const USER_BALANCE = useSelector((state) => state.UserBalance?.allData);
    const [netPayOut, setNetPayout] = useState(0);
    const calculateNetPay = (items) => items.reduce((acc, item) => acc + item.TOTAL, 0);
    const TOTAL_DISCOUNT = (total, dis) => total / 100 * dis;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirectToHome = () => {
        navigate('/login', { replace: true });
    }

    useEffect(() => {
        if (DENOMINATION_STORE_DATA?.length > 0) {
            const total = calculateNetPay(DENOMINATION_STORE_DATA);
            const finalPay = TOTAL_DISCOUNT(total, ProductDiscount);
            if (walletPayment) {
                const netPayWallet = (total - finalPay) - USER_BALANCE;
                setNetPayout(netPayWallet >= 1 ? netPayWallet : 0);
            } else {
                const np = total - finalPay;
                setNetPayout(np);
            }
        } else {
            setNetPayout(0);
        }
    }, [DENOMINATION_STORE_DATA, ProductDiscount, walletPayment, USER_BALANCE])

    return (
        <Box sx={{ marginTop: "10px", width: "100%" }}>

            {isLoggedIn ?
                (<Button
                    variant="contained"
                    fullWidth
                    disabled={!netPayOut && !walletPayment}
                    onClick={() => handleUserPayment(DENOMINATION_STORE_DATA, PRODUCT_DETAIL, USER_PROFILE, walletPayment, navigate, setPurchaseLoading, dispatch)}
                    sx={{
                        minHeight: {
                            xs: "55px",
                            lg: "60px"
                        },
                        textTransform: {
                            xs: "capitalize",
                            lg: "capitalize"
                        },
                        fontSize: {
                            xs: "1.2rem",
                            lg: "1.4rem"
                        },
                        borderRadius: { xs: 2, },
                        color: { xs: "white.main" },
                        "&: hover": {
                            boxShadow: 5,
                        }
                    }}
                >
                    <Stack alignItems="center" flexDirection="row" sx={{
                        justifyContent: "center"
                    }}>
                        {purchaseLoading ?
                            <Typography variant='button' sx={{ textTransform: "capitalize" }}>
                                Please Wait . . . . . . .
                            </Typography>
                            :
                            <Typography variant='subtitle1' sx={{ textTransform: "capitalize", fontWeight: { xs: 700, sm: 700, lg: 700 } }}>
                                {`Pay Only \u20B9 ${handleAmount(netPayOut)}`}
                            </Typography>
                        }
                    </Stack>
                </Button>)
                :
                (<Button
                    variant="contained"
                    fullWidth
                    onClick={redirectToHome}
                    sx={{
                        minHeight: {
                            xs: "55px",
                            lg: "60px"
                        },
                        textTransform: {
                            xs: "capitalize",
                            lg: "capitalize"
                        },
                        fontSize: {
                            xs: "1.2rem",
                            lg: "1.4rem"
                        },
                        fontWeight: { xs: 700, sm: 700, lg: 700 },
                        borderRadius: { xs: 2, },
                        color: { xs: "white.main" },
                        "&: hover": {
                            boxShadow: 5,
                        }
                    }}
                >
                    {`Login & Pay \u20B9`}
                    {handleAmount(netPayOut)}
                </Button>)
            }
        </Box>
    )
}

const makePurchasePayloadDenomination = (denominations) => {
    let data = [];
    for (const iterator of denominations) {
        if (iterator.QUANTITY > 0) {
            const deno = {
                "amount": iterator.AMOUNT,
                "discount": iterator.DISCOUNT,
                "quantity": iterator.QUANTITY,
                "total": iterator.TOTAL,
                "PRODUCTCODE": iterator?.PRODUCT_CODE,
                "ProductGuid": iterator?.PRODUCT_GUID,
                "product_id": iterator?.PRODUCT_ID,
                "subproduct_id": iterator?.SUB_PRODUCT_ID,
                "skuID": iterator?.SKU_ID,
            }
            data.push(deno);
        }
    }
    return data;
}

const purchaseApi = async (purchasePayload, setPurchaseLoading, navigate, dispatch) => {
    // Call the purchase api 
    const purchaseResponse = await purchaseProduct(purchasePayload);
    try {
        if (!purchaseResponse?.data?.status) {
            setPurchaseLoading(false);
            console.log("Something Went Wrong During puchasing product Try Again.")
            navigate('/profile/order', { replace: true });
        }

        const payU = purchaseResponse?.data?.data?.gateway;
        const wallet = purchaseResponse?.data?.data?.walletPaid;
        const rediredToPayU = purchaseResponse.data.data.payuSdk;

        if (rediredToPayU) {

            if (payU == true && wallet == 0) {
                // redirect to -> payU
                //User's Pay complete Payment Online
                // paymentGateway(purchaseResponse?.data, payload);
                await startPayU(purchaseResponse?.data, purchasePayload);
                return null;
            } else if (payU == true && wallet != 0) {
                // redirect to -> payU
                //User's Pay partially Payment Online and partially Payment from Wallet
                // paymentGateway(purchaseResponse?.data, payload);
                await startPayU(purchaseResponse?.data, purchasePayload)
                return null;
            } else if (payU == false && wallet != 0) {
                // redirect to -> Order's Page
                //User's Pay complete Payment Wallet
                navigate('/profile/order', { replace: true });
                return null;
            } else {
                // redirect to -> payU
                // paymentGateway(purchaseResponse?.data, payload);
                await startPayU(purchaseResponse?.data, purchasePayload);
                return null;
            }

        } else {

            if (payU == true && wallet == 0) {
                // redirect to -> payU
                //User's Pay complete Payment Online
                await payFromRazorPay(purchaseResponse?.data, purchasePayload);
                return null;
            } else if (payU == true && wallet != 0) {
                // redirect to -> payU
                //User's Pay partially Payment Online and partially Payment from Wallet
                await payFromRazorPay(purchaseResponse?.data, purchasePayload);
                return null;
            } else if (payU == false && wallet != 0) {
                // redirect to -> Order's Page
                //User's Pay complete Payment Wallet
                navigate('/profile/order', { replace: true });
                return null;
            } else {
                // redirect to -> payU
                // paymentGateway(purchaseResponse?.data, payload);
                await payFromRazorPay(purchaseResponse?.data, purchasePayload);
                return null;
            }
        }

        // dispatch(fetchUserBalance());

    } catch (error) {
        setPurchaseLoading(false);
        console.log("Something Went Wrong During puchasing product Try Again.")
        navigate('/profile/order', { replace: true });
    }

    async function startPayU(purchasedItem, payload) {
        try {
            const totalAmount = purchasedItem.totalAmount;
            const name = payload.customerName;
            const email = payload.emailID;
            const mobile = payload.mobileNo;
            const denominations = payload.denominations.map((item) => {
                return {
                    ...item,
                    amount: parseInt(Math.round(item.amount - (item.amount / 100 * item.discount))),
                    total: parseInt(Math.round(item.amount - (item.amount / 100 * item.discount)))
                };
            });


            // It first checks if the required fields (totalAmount, name, mobile, email) are filled. If not, it throws an error.
            if (totalAmount === 0) throw new Error('Please Select Denomination!');
            if (!name) throw new Error('Please Enter Receiver Name!');
            if (!mobile) throw new Error('Please Enter Receiver Mobile!');
            if (!email) throw new Error('Please Enter Receiver Email!');

            // It then sets loading.value to true, indicating that the process has started.


            // A payObject is created
            const payObject = {
                productId: payload.productId,
                walletPayment: payload.walletPayment,
                emailID: email,
                mobileNo: mobile,
                customerName: name,
                newSite: payload.newSite,
                denominations: [...denominations],
                device_type: 0,
            };

            // It then makes an asynchronous API call to placeOrder with payObject as the argument
            const response = await paymentGateway(payObject, purchasedItem);
            const { status, data, message } = response;

            if (!status) throw new Error(message);
            if (data.gateway) {
                // paymentGateway(data)
            } else {
                // $store.dispatch('notify/success', message);
                // $router.push({name: 'reports'})
            }
            // loading.value = true;

        } catch (error) {

        }
    }

    async function paymentGateway(data, purchase) {
        try {
            const payUObject = { ...data, ...purchase.data.product_info };
            const form = document.createElement("form");
            form.setAttribute('method', 'post');
            form.setAttribute('action', 'https://secure.payu.in/_payment');
            form.setAttribute('service_provider', 'payu_paisa');
            form.setAttribute('target', 'payumoney');
            form.setAttribute('target', '_self');

            for (const prop in payUObject) {
                if (payUObject.hasOwnProperty(prop)) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = prop;
                    input.value = payUObject[prop];
                    form.appendChild(input);
                }
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {

        }
    }

    async function payFromRazorPay(purchasedItem, payload) {
        const name = payload.customerName;
        const email = payload.emailID;
        const mobile = payload.mobileNo;
        let RZPPayload = {}; 
        const productId = payload.denominations[0]['product_id'];

        if (productId == 860) {
            const line_items_data = () => {
                const lineItems = [];
                payload.denominations.forEach(element => {
                    const obj = {
                        "type": "amazon_giftcard",
                        "sku": "amazon_pay_gc",
                        "variant_id": purchasedItem.data.receipt,
                        "price": element.total,
                        "quantity": element.quantity,
                    }

                    lineItems.push(obj);
                });

                return lineItems;
            }

            // Define Razorpay payload
            RZPPayload = {
                "key": process.env.REACT_APP_99GIFT_LIVE_RZPKEY,
                "amount": purchasedItem?.data?.totalAmount,
                "currency": "INR",
                "name": "Zpay Solutions Pvt Ltd",
                "description": "Transaction",
                "image": "https://www.99gift.in/static/media/logo.1f56873eb49af1faa202.png",
                "order_id": purchasedItem?.data?.razorpay_ordid,
                "handler": function (response) {
                    // Handle Razor pay Success and failure 
                    if (response.razorpay_payment_id) {
                        actionNotifier.success('Payment was successful!', {
                            position: "top-right",
                            autoClose: 5000
                        });
                    } else {
                        actionNotifier.error('Payment failed. Please try again.', {
                            position: "top-right",
                            autoClose: 5000
                        });
                    }
                    // Redirect on Orders page
                    navigate('/profile/order', { replace: true });
                    setPurchaseLoading(false);
                },
                "prefill": {
                    "name": name,
                    "email": email,
                    "contact": mobile
                },
                "notes": {
                    "internal_order_id": purchasedItem?.data?.receipt
                },
                "line_items_total": purchasedItem?.data?.totalAmount,
                "line_items": [
                    ...line_items_data()
                ],
                "customer_details": {
                    "name": name,
                    "email": email,
                    "contact": mobile
                },
                "theme": {
                    "color": "#3399cc"
                },
                modal: {
                    ondismiss: () => {
                        console.log("Payment cancelled or failed by user.");
                        actionNotifier.error('Payment was cancelled or failed.', {
                            position: "top-right",
                            autoClose: 5000
                        });
                        navigate('/profile/order', { replace: true });
                        setPurchaseLoading(false);
                    }
                }
            };
        } else {
            // Define Razorpay payload
            RZPPayload = {
                "key": process.env.REACT_APP_99GIFT_LIVE_RZPKEY,
                "amount": purchasedItem?.data?.totalAmount,
                "currency": "INR",
                "name": "Zpay Solutions Pvt Ltd",
                "description": "Transaction",
                "image": "https://www.99gift.in/static/media/logo.1f56873eb49af1faa202.png",
                "order_id": purchasedItem?.data?.razorpay_ordid,
                "handler": function (response) {
                    // Handle Razor pay Success and failure 
                    if (response.razorpay_payment_id) {
                        actionNotifier.success('Payment was successful!', {
                            position: "top-right",
                            autoClose: 5000
                        });
                    } else {
                        actionNotifier.error('Payment failed. Please try again.', {
                            position: "top-right",
                            autoClose: 5000
                        });
                    }
                    // Redirect on Orders page
                    navigate('/profile/order', { replace: true });
                    setPurchaseLoading(false);
                },
                "prefill": {
                    "name": name,
                    "email": email,
                    "contact": mobile
                },
                "notes": {
                    "internal_order_id": purchasedItem?.data?.receipt
                },
                "theme": {
                    "color": "#3399cc"
                },
                modal: {
                    ondismiss: () => {
                        console.log("Payment cancelled or failed by user.");
                        actionNotifier.error('Payment was cancelled or failed.', {
                            position: "top-right",
                            autoClose: 5000
                        });
                        navigate('/profile/order', { replace: true });
                        setPurchaseLoading(false);
                    }
                }
            };
        }

        console.log(RZPPayload);

        // Initialize Razorpay instance with the provided options
        const rzpInstance = new window.Razorpay(RZPPayload);
        rzpInstance.open();

        // Payment success and failure handling
        rzpInstance.on('payment.failed', function (response) {
            // You can handle failed payments here
            actionNotifier.error('Payment was cancelled or failed.', {
                position: "top-right",
                autoClose: 5000
            });
            setPurchaseLoading(false);
            navigate('/profile/order', { replace: true });
        });
    }
}

function handleUserPayment(deno, product, user, wallet, navigate, setPurchaseLoading, dispatch) {
    setPurchaseLoading(true);
    const filteredDeno = makePurchasePayloadDenomination(deno);
    const productId = product?.['id'];

    const purchasePayload = {
        customerName: user?.data?.name,
        emailID: user?.data?.email,
        mobileNo: user?.data?.mobile,
        newSite: true,
        walletPayment: wallet,
        productId,
        device_type: 0,
        denominations: filteredDeno,
    }

    purchaseApi(purchasePayload, setPurchaseLoading, navigate, dispatch);
}
export default UserPayButton