import React, { useEffect } from 'react';
import { Box, Grid, Typography, List, ListItem, Divider, Checkbox, Button, } from "../../../imports/muiImport";
import { useState, useSelector, useDispatch } from "../../../imports/generalImport";
import UserDetailPayment from './paymentUtils/UserDetailPayment';
import UserPayButton from './paymentUtils/UserPayButton';
import { useAuth } from '../../../hooks/contexts/AuthContext';
import PaymentProcessLoading from './paymentUtils/PaymentProcessLoading';
import PaymentOrderReceipt from './paymentUtils/PaymentOrderReceipt';
import WalletPaymentConfirm from '../../../components/alertPopup/WalletPaymentConfirm';
import PayFromWallet from './paymentUtils/PayFromWallet';


function Payment({ ProductDiscount, productDetail }) {
    const [walletPayment, setWalletPayment] = useState(false);
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);
    const USER_PROFILE = useSelector((state) => state.UserProfile?.allData);
    const [netPay, setNetPay] = useState(0);
    const { isLoggedIn } = useAuth();



    useEffect(() => {
        if (USER_PROFILE?.['data']?.['id'] == 70053) {
            setSelectedUser(true);
        } else {
            setSelectedUser(false);
        }
    }, [USER_PROFILE]);


    return (
        <Grid container
            maxWidth="false"
            sx={{
                py: { xs: 1, sm: 3, lg: 2 },
                px: { xs: 2, sm: 2, lg: 2 },
                minWidth: "100%",
                alignContent: {
                    lg: "flex-start"
                },
                border: 1,
                borderRadius: {
                    xs: 2,
                    lg: 3,
                },
                borderColor: "lightSecondary.main",
                boxShadow: { xs: 0, sm: 5, lg: 1 },
            }}>
            <Grid item xs={12}>
                <PaymentProcessLoading open={purchaseLoading} />
            </Grid>

            <Grid item xs={12} lg={isLoggedIn ? 5 : 12} xl={isLoggedIn ? 6 : 12} sx={{ pr: { lg: 1 } }} >
                <PaymentOrderReceipt ProductDiscount={ProductDiscount} setNetPay={setNetPay} />
            </Grid>

            {isLoggedIn &&
                <>
                    {/* Receiver Details */}
                    <Grid item xs={12} lg={isLoggedIn ? 7 : 12} xl={isLoggedIn ? 6 : 12}>
                        <UserDetailPayment />
                    </Grid>

                    {/* Wallet Payment Confirmation */}
                    {!selectedUser && <Grid item xs={12}>
                        <PayFromWallet walletPayment={walletPayment} setWalletPayment={setWalletPayment} />
                    </Grid>
                    }
                </>
            }

            {/* User Pay Button */}
            <Grid item xs={12}
                sx={{
                    position: { xs: "sticky", lg: "relative" },
                    bottom: 0,
                    zIndex: 999,
                }}
            >
                <UserPayButton isLoggedIn={isLoggedIn} handlePayment={netPay} ProductDiscount={ProductDiscount} productDetail={productDetail} walletPayment={walletPayment} purchaseLoading={purchaseLoading} setPurchaseLoading={setPurchaseLoading} />
            </Grid>

        </Grid>
    );

}


export default Payment