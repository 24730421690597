import React from 'react';
import { Box } from "../../../imports/muiImport";
import { christmasProductSantaIcon } from "../../../imports/generalImport";

function MobileProductDetailImage({ image }) {
    return (
        <>
            <Box
                component={'img'}
                src={image}
                width={312}
                height={160}
                sx={{
                    maxWidth: "75vw",
                    // boxShadow: 4,
                    // borderRadius: 4,
                    position: 'relative',
                    left: 10,
                    top: -15,
                    objectFit: 'contain',
                    zIndex: 0,
                }} />

            <Box
                component={'img'}
                src={christmasProductSantaIcon}
                width={312}
                height={190}
                sx={{
                    maxWidth: "75vw",
                    position: 'absolute',
                    left: '-75px',
                    top: 86,
                    zIndex: 1,
                    // boxShadow: 4,
                    // borderRadius: 4,
                }}
            />

        </>
    )
}

export default MobileProductDetailImage;