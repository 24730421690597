import { React, useDispatch, useEffect, useState, useNavigate, useParams, useSelector, getProductByTitle, titleToUrl, christmasMobileBG3, christmasMobileBG4 } from "../../../imports/generalImport";
import { Box, Typography, Container, Button } from "../../../imports/muiImport";

import { setProductDetails, wipeOutProductDetails } from '../../../store/productInfo/action';
import { setNewDenomination, wipeOutDenomination } from '../../../store/productDetails/action';
import { createDenominationData, fetchProductById, isProductLive, validateOutOfStock } from '../../../pages/PRODUCTDETAILS/productDetailHook';
import { CircularProgress } from "@mui/material";
import MobileProductDetailImage from "../../mobileComponents/mobileProductDetails/MobileProductDetailImage";
import MobileProductDetails from "../../mobileComponents/mobileProductDetails/MobileProductDetails";
import MobileGiftCardPurchase from "../../mobileComponents/mobileGiftCardPurchase/MobileGiftCardPurchase";
import ScrollToTop from '../../../components/other/ScrollToTop';
import { Helmet } from "react-helmet-async";
import PageNotFound from "../../../pages/OTHERPAGE/PageNotFound";

function MobileHomeProductDetails() {
    const [currentProductId, setCurrentProductId] = useState(null);
    const [productLoading, setProductLoading] = useState(true);
    const [outOfStock, setOutOfStock] = useState(null);
    const [showRangeDenomination, setShowRangeDenomination] = useState(false);
    const [productFound, setProductFound] = useState(false);


    const ProductDetail = useSelector((state) => state.ProductDetail?.allData);

    let { title: urlTitle, id: urlId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const canonicalUrl = `https://www.99gift.in/card-details/${urlId}/${titleToUrl(urlTitle)}`;


    useEffect(() => {
        setProductLoading(true);
        if (urlId != currentProductId) {
            dispatch(wipeOutProductDetails()); // clear product Details 
            dispatch(wipeOutDenomination()); // clear previous denomination
            productload(urlId, urlTitle);
            setCurrentProductId(urlId);
        } else {
            // Redirect to home page
            navigate('/');
        }
    }, [urlId, urlTitle]);


    if (productFound) {
        return (<PageNotFound />);
    }

    if (productLoading) {
        return (
            <Box sx={{
                minHeight: "100vh",
                // minWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>{`Buy ${urlTitle} - 99gift.in`}</title>
                    <meta name="description" content={`Get instant discounts on ${urlTitle} when you purchase them online through 99gift. Take advantage of great deals on ${urlTitle}, coupons, and promo codes.`} />
                    <meta property="og:title" content={`Buy ${urlTitle} - 99gift.in`} />
                    <meta property="og:description" content={`Get instant discounts on ${urlTitle} when you purchase them online through 99gift. Take advantage of great deals on ${urlTitle}, coupons, and promo codes.`} />
                </Helmet>

                <Container sx={{
                    minHeight: "100vh",
                    // backgroundColor: "#EEF7FF",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    pt: 3,
                    mb: 6,

                    backgroundImage: `url(${christmasMobileBG4})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: '100%',
                }}>
                    <ScrollToTop />
                    <MobileProductDetailImage image={ProductDetail?.image} />
                    <MobileProductDetails product={ProductDetail} />

                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            py: 2,
                            // bgcolor: "#eef7ff",
                            backgroundColor: "transparent",
                            zIndex: 1000
                        }}
                    >

                        {outOfStock ?
                            (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        textTransform: "capitalize",
                                        py: 1,
                                        bgcolor: 'error.main',
                                        color: "#fff",
                                        fontWeight: "bold",
                                        fontSize: "1.2rem",
                                        borderRadius: 0,
                                        "&:hover": {
                                            bgcolor: 'error.dark',
                                        },
                                    }}
                                >
                                    Currently Out of Stock
                                </Button>
                            )
                            :
                            (
                                <MobileGiftCardPurchase showRangeDenomination={showRangeDenomination} />
                            )
                        }

                    </Box>

                </Container>
            </>
        );
    }

    async function productload(urlId, urlTitle) {
        // TODO : Start Loading 
        const productFetched = await fetchProductById(urlId, urlTitle);
        const validateProductTitleResponse = validateProductTitle(productFetched?.data, urlTitle);


        if (productFetched?.status && validateProductTitleResponse) {
            dispatch(setProductDetails(productFetched?.data));
            const isProductLiveStatus = isProductLive(productFetched?.data.front);

            if (!isProductLiveStatus) {
                // This Porject is Live
                // Check Current Product Range or Denomination

                const apiId = productFetched?.data?.api_id;
                const front = productFetched?.data?.front;
                const denom = productFetched?.data?.denomination;
                const validateResponse = validateOutOfStock(apiId, front, denom);
                const outOfStockStatus = validateResponse.outOfStockStatus;
                const rangeDenominationStatus = validateResponse.rangeDenominationStatus;

                if (outOfStockStatus) {
                    // this product is out of stock
                    setOutOfStock(true);
                    setProductLoading(false);
                    return null;
                }


                if (rangeDenominationStatus) {
                    // this product is Range
                    setOutOfStock(false);
                    setProductLoading(false);
                    setShowRangeDenomination(true);
                    return null;
                }


                if (!outOfStockStatus && !rangeDenominationStatus) {
                    // this product denomination is already
                    setOutOfStock(false);
                    setProductLoading(false);
                    setShowRangeDenomination(false);

                    const newDeno = createDenominationData(productFetched?.data)
                    dispatch(setNewDenomination(newDeno));
                    return null;
                }
            } else {
                setOutOfStock(true);
                setProductLoading(false);
                return null;
            }
        } else {
            setProductFound(true);
        }

        // TODO : End Loading
        setProductLoading(false);
        return null;
    }



    function validateProductTitle(actualData, urlTitle) {
        const userGivenTitle = urlTitle.toLowerCase();
        const ourProductTitle = actualData?.title.toLowerCase();
        const ourFinalTitle = titleToUrl(ourProductTitle);

        if (ourFinalTitle === userGivenTitle) {
            setProductFound(false);
            return true;
        } else {
            setProductFound(true);
            return true;
        }
    }
}

export default MobileHomeProductDetails