import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, Divider } from "../../../../imports/muiImport";
import { useSelector } from "../../../../imports/generalImport";
import { handleAmount } from '../../../../shared/extra';
function PaymentOrderReceipt({ ProductDiscount, setNetPay }) {
    const DENOMINATION_STORE_DATA = useSelector((state) => state.Denominations?.allData);
    const [paymentData, setPaymentData] = useState({
        TOTAL_VALUE: 0,
        DISCOUNT: 0,
        TOTAL_DISCOUNT: 0,
        REWARD_POINTS: 0,
        NET_PAY: 0,
    })

    useEffect(() => {
        if (DENOMINATION_STORE_DATA?.length > 0) {
            filterUserDenomination(DENOMINATION_STORE_DATA)
        } else {
            setPaymentData({
                TOTAL_VALUE: 0,
                DISCOUNT: 0,
                TOTAL_DISCOUNT: 0,
                REWARD_POINTS: 0,
                NET_PAY: 0,
            })
        }
    }, [DENOMINATION_STORE_DATA]);

    return (
        <Box sx={{ px: { sm: 0, md: 0, lg: 0, xl: 1 } }}>
            <Typography
                variant="body1"
                gutterBottom
                sx={{
                    color: {
                        xs: "primary.main",
                        sm: "textPrimary.main"
                    },
                    fontSize: {
                        xs: "1.2rem",
                        sm: "13.5px",
                        lg: "15px",
                    },
                    fontWeight: { xs: 700, sm: 700, lg: 700 },
                    textAlign: { xs: "center", lg: "left" },
                    mb: { xs: 1, lg: 0 },
                    borderBottom: { xs: 1, lg: 0 },
                    mt: { xs: 1, sm: 0, lg: 0 }
                }}
            >
                Order Review
            </Typography>

            <Box
                sx={{
                    borderRadius: { xs: 1, lg: 2 },
                    backgroundColor: { xs: "backgroundPrimary.main", lg: "#0028650a" },
                    py: { xs: 0, lg: "10px" },
                    px: { xs: 0, lg: "8px" },
                    minHeight: { xs: "100px", lg: "110px" }
                }}
            >

                <List>
                    <ListItem>
                        <Typography variant="subtitle1" sx={{
                            minWidth: "65%",
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                            fontWeight: { xs: 700, sm: 700, lg: 700 }
                        }}>Total Value</Typography>
                        <Typography variant="subtitle1" sx={{
                            color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                            minWidth: "35%",
                            textAlign: { xs: "center", sm: "left", lg: "center" },
                        }}> &#x20B9; {handleAmount(paymentData?.TOTAL_VALUE)}</Typography>
                    </ListItem>
                    <Divider component="li" />

                    <ListItem
                        sx={{
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="subtitle1" sx={{
                            minWidth: "65%",
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                        }}>Discount
                            ({ProductDiscount || 0}%)</Typography>
                        <Typography variant="subtitle1" sx={{
                            color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                            minWidth: "35%",
                            textAlign: { xs: "center", sm: "left", lg: "center" },
                        }}> &#x20B9; {handleAmount(paymentData?.TOTAL_DISCOUNT || 0)}</Typography>
                    </ListItem>
                    <Divider component="li" />

                    <ListItem
                        sx={{
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="subtitle1" sx={{
                            minWidth: "65%",
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                        }}>Reward Points</Typography>
                        <Typography variant="subtitle1" sx={{
                            color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                            minWidth: "35%",
                            textAlign: { xs: "center", sm: "left", lg: "center" },
                        }}> {handleAmount(paymentData?.REWARD_POINTS)}</Typography>
                    </ListItem>
                    <Divider component="li" />

                    <ListItem
                        sx={{
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="subtitle1" sx={{
                            minWidth: "65%",
                            color: "primary.main",
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "13.5px" },
                            textTransform: "capitalize"
                        }}>Total Payout</Typography>
                        <Typography variant="subtitle1" sx={{
                            color: "primary.main",
                            fontWeight: { xs: 700, sm: 700, lg: 700 },
                            fontSize: { xs: "13.5px", sm: "14.5px" },
                            minWidth: "35%",
                            textAlign: { xs: "center", sm: "left", lg: "center" },
                        }}> &#x20B9; {handleAmount(paymentData?.NET_PAY)}</Typography>
                    </ListItem>

                </List>

            </Box>
        </Box>
    );

    function filterUserDenomination() {
        const calculateTotalSum = (items) => items.reduce((acc, item) => acc + item.TOTAL, 0);
        const filteredData = DENOMINATION_STORE_DATA.filter(item => item.QUANTITY >= 1);
        const TOTAL_VALUES = calculateTotalSum(filteredData);
        const TOTAL_DISCOUNT = TOTAL_VALUES / 100 * (filteredData?.[0]?.DISCOUNT || ProductDiscount);
        const NET = TOTAL_VALUES - TOTAL_DISCOUNT;
        const data = {
            TOTAL_VALUE: TOTAL_VALUES,
            DISCOUNT: ProductDiscount || filteredData?.[0]?.DISCOUNT,
            TOTAL_DISCOUNT: TOTAL_DISCOUNT,
            REWARD_POINTS: 0,
            NET_PAY: NET || 0,
        }
        setPaymentData(data);
    }
}





export default PaymentOrderReceipt