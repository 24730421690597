import React, { useEffect } from 'react';
import { Backdrop, Button } from '../../../imports/muiImport';
import { Box, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import close icon
import { christmasPopUp2 } from '../../../imports/generalImport';
import { useNavigate } from 'react-router-dom';

const ChristmasPopUp = () => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClick = () => {
        navigate('/card-list');
        handleClose();
    };

    useEffect(() => {
        handleOpen();
    }, []);

    return (
        <>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: 9999 })}
                open={open}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: {
                            xs: '90%',
                            sm: '80%',
                            md: '100%',
                        },
                        height: {
                            xs: 'auto',
                            sm: '80%',
                            md: '100%',
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '100%',
                        maxHeight: '100vh',
                        animation: 'myAnim 2s ease 0s 1 normal forwards', // Apply the animation
                        '@keyframes myAnim': { // Define the animation
                            '0%': {
                                transform: 'scale(0.5)',
                            },
                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                        cursor: 'pointer' // Add pointer cursor
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: { xs: -50, md: 6 },
                            right: { xs: 0, md: '32%' },
                            color: '#fff',
                            zIndex: 100,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker background on hover
                            },
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Christmas Image */}
                    <Box
                        component={'img'}
                        src={christmasPopUp2}
                        sx={{
                            width: {
                                xs: '100%',
                                md: '100%',
                            },
                            height: {
                                xs: '100%',
                                md: '90%',
                            },
                            objectFit: 'contain',
                        }}
                        onClick={handleClick}
                    />
                </Box>
            </Backdrop>
        </>
    );
};

export default ChristmasPopUp;
