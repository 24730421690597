import React from "react";
import Container from "@mui/material/Container";
import { Grid, Typography, Box } from "@mui/material";

const giftBuyGuide = () => {
    return (
        <Container maxWidth="xl" sx={{ my: 5 }}>
            <Grid
                Container
                sx={{
                    // borderRadius: "10px",
                    boxShadow: { xs: 0, md: 0 },
                    p: { xs: 0, sm: 4, md: 4 },
                    backgroundColor: { xs: 'transparent', sm: 'transparent' }
                }}
            >
                <Grid item xs={12} sx={{
                    mb: { xs: 4, md: 3, lg: 4 },
                    py: { xs: 2, sm: 0 },
                    px: { xs: 0, sm: 0 },
                    backgroundColor: { xs: '#fefafe', sm: 'transparent' }
                }}>
                    {headingTitle("Best Gift Card", "Selection", 'h1')}
                    {paragraphText(
                        `Welcome to 99gift.in, your ultimate destination for <strong>Gift Cards</strong> and <strong>Gift Vouchers</strong> in India! Whether you're looking to <strong>Buy Gift Cards Online</strong> or find the best <strong>Discount Gift Cards</strong>, we have you covered. Explore a wide variety of <strong>e-gift cards India</strong> and enjoy exciting <strong>gift card offers</strong> on top brands like Myntra, <a href="https://www.99gift.in/card-details/644/nykaa-fashion-e-gift-card">Nykaa</a>, and more. Get your favorite <strong>gift card at a discount</strong> and shop <strong>Gift Cards</strong> with ease. At 99gift.in, we offer the best <strong>gift card deals</strong> and <strong>gift card discounts</strong> to make gifting simple and affordable. <strong>Buy discounted gift cards</strong> today and discover why 99gift.in is the top choice for <strong>Gift Card Online India</strong>!`
                    )}
                </Grid>
                <Grid item xs={12} sx={{
                    mb: { xs: 4, md: 3, lg: 4 },
                    py: { xs: 2, sm: 0 },
                    px: { xs: 0, sm: 0 },
                    backgroundColor: { xs: '#fefafe', sm: 'transparent' }
                }}>
                    {headingTitle("The Ease of Purchasing ", "E-Gift-Vouchers", 'h2')}
                    {paragraphText(
                        "Gone are the days of hopping from store to store in search of the perfect Gift Cards. 99Gift.in brings convenience to your fingertips with their e-voucher collection. Purchasing these e-gift vouchers is as simple as a few clicks, saving you time and effort. The platform offers a seamless transaction process, ensuring a hassle-free experience from selection to delivery. With just a few taps, you can send an e-voucher directly to the recipient, making gifting effortless and instantaneous."
                    )}
                </Grid>
                <Grid item xs={12} sx={{
                    mb: { xs: 4, md: 3, lg: 4 },
                    py: { xs: 2, sm: 0 },
                    px: { xs: 0, sm: 0 },
                    backgroundColor: { xs: '#fefafe', sm: 'transparent' }
                }}>
                    {headingTitle("Unlocking Exciting", "Discounts", 'h2')}
                    {paragraphText(
                        "Who doesn't love a good deal? At 99Gift.in, unlocking exciting discounts is part of the charm. The platform regularly features discount gift vouchers, enabling buyers to maximize their savings while gifting. These vouchers offer discounts on a wide array of products or services, providing the recipient with not just a thoughtful Gift Cards, but also additional value. Keep an eye out for these vouchers to make your gifting experience more budget-friendly and rewarding."
                    )}
                </Grid>
                <Grid item xs={12} sx={{
                    mb: { xs: 4, md: 3, lg: 4 },
                    py: { xs: 2, sm: 0 },
                    px: { xs: 0, sm: 0 },
                    backgroundColor: { xs: '#fefafe', sm: 'transparent' }
                }}>
                    {headingTitle("Navigating the ", "Range of Offerings", 'h2')}
                    {paragraphText(
                        "99Gift.in boasts an extensive range of offerings, making navigation a delightful experience. From categories encompassing fashion, electronics, dining, and more, the platform's interface simplifies browsing. Whether you're searching for a specific brand or exploring options based on interests, the user-friendly layout ensures easy navigation. Each category is meticulously curated, allowing users to swiftly find the perfect Gift Cards or voucher."
                    )}
                </Grid>
                <Grid item xs={12} sx={{
                    mb: { xs: 4, md: 3, lg: 4 },
                    py: { xs: 2, sm: 0 },
                    px: { xs: 0, sm: 0 },
                    backgroundColor: { xs: '#fefafe', sm: 'transparent' }
                }}>
                    {headingTitle("Elevating Emotions through", "Gift Cards", 'h2')}
                    {paragraphText(
                        "Gift cards have evolved beyond being a mere present. They are a canvas to express emotions, and 99Gift.in recognizes this sentiment. Through the platform's diverse range of gift cards and customization options, emotions can be elevated. Whether it's conveying gratitude, celebrating milestones, or simply expressing love, gift cards offer a tangible representation of these feelings. Personalize your message, select the perfect Gift Cards, and elevate your gifting game by letting the emotions speak through these thoughtful gestures."
                    )}
                </Grid>


                <Grid item xs={12} sx={{
                    mb: { xs: 4, md: 3, lg: 4 },
                    py: { xs: 2, sm: 0 },
                    px: { xs: 0, sm: 0 },
                    backgroundColor: { xs: '#fefafe', sm: 'transparent' }
                }}>
                    {headingTitle("Shop for the best gift cards from", "top brands", 'h3')}
                    {paragraphTextInterLink(
                        `Discover an incredible selection of gift cards from popular brands, covering fashion, tech, entertainment, and daily essentials.
                        `
                    )}

                    {paragraphTextInterLink(
                        `Choose from renowned brands like <a href="https://www.99gift.in/card-details/484/myntra-e-gift-card">Myntra </a>for the latest fashion trends,  <a href="https://www.99gift.in/card-details/490/reliance-smart-e-gift-voucherbrand-voucher">Reliance Smart</a> for daily essentials, <a href="https://www.99gift.in/card-details/489/reliance-digital-e-gift-voucher-brand-voucher">Valorant</a> for tech products, and     <a href="https://www.99gift.in/card-details/461/trends-reliance-e-gift-card">Reliance Trends</a> or <a href="https://www.99gift.in/card-details/684/pantaloons-">Pantaloons</a> for fashion. For fashion and beauty lovers,   <a href="https://www.99gift.in/card-details/813/lifestyle-online-wwwlifestylestorescom">Lifestyle</a> and <a href="https://www.99gift.in/card-details/644/nykaa-fashion-e-gift-card">Nykaa</a> are perfect options, while <a href="https://www.99gift.in/card-details/692/amazon-fresh-gift-voucher">Amazon Fresh</a> caters to grocery     needs.
                        Entertain with gaming gift cards from <a href="https://www.99gift.in/card-details/700/valorant-vp-points-2575-vp-gift-card">Valorant</a>, <a href="https://www.99gift.in/card-details/819/roblox-gift-card-code---800-robux-or-10">Roblox</a>, and <a href="https://www.99gift.in/card-details/711/unipin-e-gift-card">Unipin</a>, providing access to the latest games, apps, and in-game purchases.
                        
                        <br/>
                            
                        You can find <strong>bulk orders</strong> for <a href="https://www.99gift.in/corporates">corporate gift</a> cards here, offering your employees, partners, and customers the power of choice with over 200 brands.
                        `
                    )}

                    {paragraphTextInterLink(
                        `At 99gift.in, gifting is made easy with a wide range of gift cards from favorite brands, offering something for everyone.`
                    )}
                </Grid>

            </Grid>
        </Container>
    );

    function headingTitle(primaryTitle, secondaryTitle, variant = 'h3') {
        return (
            <Box sx={{
                textAlign: { xs: 'center', sm: 'left' },
                mb: { xs: 1, sm: 0 }
            }}>
                <Typography
                    className="text__title"
                    variant={variant}
                    gutterBottom
                    sx={{
                        color: "textPrimary.main1",
                        fontWeight: 500,
                        fontSize: { xs: "18px", sm: "16px", md: "16px", lg: "17px", xl: "16px" },
                        lineHeight: { xs: "20px" },
                        mb: "4px"
                    }}
                >
                    {primaryTitle}
                    {secondaryTitle && (
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: "18px", sm: "16px", md: "16px", lg: "17px", xl: "16px" },
                                display: "inline-block",
                                ml: "3px",
                                lineHeight: { xs: "20px", lg: "22px" },
                                mb: 0,
                                fontWeight: 500,
                            }}
                            color="secondary.main"
                        >
                            {secondaryTitle}
                        </Typography>
                    )}
                </Typography>
            </Box>
        );
    }

    function paragraphText(description = null) {
        return (
            <Typography
                component={'p'}
                variant="subtitle1"
                gutterBottom
                sx={{
                    fontSize: { xs: "14px", sm: "13.5px", md: "13.5px", lg: "15.5px", xl: "15.5px" },
                    lineHeight: 1.8,
                    color: "textPrimary.main1",
                    textIndent: { xs: 0, sm: "10px", md: "10px", lg: "15px", xl: "18px" },
                    '& a': {
                        color: "#1976d2",
                        textDecoration: "none",
                        '&:hover': {
                            textDecoration: "underline",
                        },
                    },
                    '& b': {
                        fontWeight: "bold",
                    },
                    "& strong": {
                        color: "secondary.main",
                        fontWeight: 500,
                    }
                }}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        );
    }

    function paragraphTextInterLink(description = null) {
        return (
            <Typography
                component={'p'}
                variant="subtitle1"
                gutterBottom
                sx={{
                    fontSize: { xs: "14px", sm: "13.5px", md: "13.5px", lg: "15.5px", xl: "15.5px" },
                    lineHeight: 1.8,
                    color: "textPrimary.main1",
                    '& a': {
                        color: "secondary.main",
                        fontWeight: 500,
                        textDecoration: "underline",
                        '&:hover': {
                            textDecoration: "underline",
                        },
                    },
                    '& b': {
                        fontWeight: "bold",
                    },
                    "& strong": {
                        color: "secondary.main",
                        fontWeight: 500,
                    },

                }}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        );
    }
};

export default giftBuyGuide;
