import React, { useEffect } from 'react';

function SeoMetaTag({ title, description, keywords = [], ogTitle, ogDescription, ogImage, ogUrl, canonical }) {


    useEffect(() => {
        document.title = title;

        if (description) setMetaTag('name', 'description', description);
        if (keywords.length > 0) setMetaTag('name', 'keywords', keywords.join(', '));
        if (title) setMetaTag('property', 'og:title', ogTitle || title);
        if (description) setMetaTag('property', 'og:description', ogDescription || description);
        if (ogImage) setMetaTag('property', 'og:image', ogImage);
        if (canonical) setCanonicalTag(canonical);
        setMetaTag('property', 'og:url', ogUrl || window.location.href);
    }, [title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl, canonical]);

    const setMetaTag = (attr, key, content) => {
        if (content) {
            let element = document.querySelector(`meta[${attr}="${key}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    };

    const setCanonicalTag = (url) => {
        let link = document.querySelector('link[rel="canonical"]');
        if (!link) {
            link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            document.head.appendChild(link);
        }
        link.setAttribute('href', url);

    };

    return null; // If no JSX needs to be rendered, return null.
}

export default SeoMetaTag;
