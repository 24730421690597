import React from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import about from "../../assets/others/about.jpeg";
import about1 from "../../assets/others/about1.jpeg";
import aboutVideo from "../../assets/others/about_video.jpeg";
import FaqSection from "./FaqSection";
import HeaderBanner from "../../assets/others/about-us.png";
import CompanyPortfolio from "./CompanyPortfolio";
import PageBreadcrumb from "../../components/other/PageBreadcrumb";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



const AboutPage = (props) => {
  const { isMobile = false } = props;
  const Mobile = useMediaQuery("(max-width:480px)");
  const location = useLocation();
  const canonicalUrl = `https://www.99gift.in${location.pathname}`;
  const seoData = {
    title: 'About us'
  }

  return (
    <React.Fragment>

      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About 99Gift" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content="About Us" />
        <meta property="og:description" content="About 99Gift" />
        <link rel="canonical" href={canonicalUrl} /> {/* Dynamic Canonical URL */}
      </Helmet>


      {!isMobile &&
        (
          <Container maxWidth="xl">
            <PageBreadcrumb
              home="Home"
              currentProduct="About Us"
            />
          </Container>
        )
      }

      <Container maxWidth="false" disableGutters>
        <Box sx={{
          objectFit: "cover",
          minHeight: {
            xs: "130px",
            sm: "130px",
            md: "130px",
            lg: "216px"
          },
          display: "flex",
          justifyContent: "center"
        }}>
          <img src={HeaderBanner} alt="Header Banner" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
        </Box>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          py: { xs: 0, md: 4 },
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: { xs: "center" },
            p: { xs: 0, sm: "2rem 1rem", lg: 4 },
            backgroundColor: "white.main",
            borderRadius: 2,
          }}
        >
          <Grid item xs={12} md={8}>
            {/* About content */}
            <Box>
              <Typography
                variant="h6"
                color="secondary.main"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: ".7rem", sm: "14px" },
                  mb: { xs: 0, sm: 1 },
                }}
              >
                Hi! We Are Your E-Gifting Partner
              </Typography>

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: { xs: 1, sm: 2, md: 2, lg: 1 },
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h1"
                  color="dark.main"
                  sx={{
                    display: "inline",
                    textTransform: "capitalize",
                    fontSize: { xs: "1.4rem", sm: "18px", lg: "1.6rem", xl: "1.8rem" },
                    fontWeight: { xs: 400, sm: 500 },
                  }}
                >
                  Our experience will help <br /> you create
                  <Typography
                    variant="h1"
                    color="secondary.main"
                    sx={{
                      display: "inline",
                      ml: "3px",
                      textTransform: "capitalize",
                      fontSize: { xs: "1.4rem", sm: "18px", lg: "1.6rem", xl: "1.8rem" },
                      fontWeight: { xs: 400, sm: 500 },
                    }}
                  >
                    A Good Gifting Experience
                  </Typography>
                </Typography>
              </Box>

              <Typography
                variant="body1"
                sx={{
                  mb: { xs: 1, sm: 1 },
                  fontSize: { xs: "0.8rem", sm: "12px", md: "0.9rem", lg: "1.1rem", xl: "1rem" },
                  color: { xs: "textPrimary.main", sm: "dark.main" },
                  textAlign: { xs: "justify" }
                }}
              >
                99Gift is a Leading Online e-Gift Card provider. 99Gift provides e-Gift cards with great discounts on prominent brands in all over India. We introduce 250+ brand e-gift cards with greatest discounts available for Apparels, Tours & Travels, Food, Entertainment, Grocery, Mobile, Health Care products and many more. We are here to solve all final moment gifting needs with one hand solutions with liberty to find shops and outlets near their office or home.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: { xs: 1, sm: 1 },
                  fontSize: { xs: "0.8rem", sm: "12px", md: "0.9rem", lg: "1.1rem", xl: "1rem" },
                  color: { xs: "textPrimary.main", sm: "dark.main" },
                  textAlign: { xs: "justify" }
                }}
              >
                Our company is the best solution to avail it by the mode of different online platforms. We are here to provide you a reasonable solution by including discounts and offers. Within a few clicks we can transfer or receive our gift card. It is an instant solution for solving the gifting needs of each and every individual. Our site is giving you a nice platform to gain a gleeful moment.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: { xs: 1, sm: 1 },
                  fontSize: { xs: "0.8rem", sm: "12px", md: "0.9rem", lg: "1.1rem", xl: "1rem" },
                  color: { xs: "textPrimary.main", sm: "dark.main" },
                  textAlign: { xs: "justify" }
                }}
              >
                If you buy gift card from our site then you will be profitable in each and every manner. The services of our site are quick and efficient. You cannot deny to take our services. Just buy a gift card from our site and earn the gleeful feeling of giving blessings and love to your loved ones.
              </Typography>


            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} sx={{ mt: { sm: 2, md: 3 } }}>
            {/* About us image */}
            <Box sx={{ position: "relative" }}>
              <Box
                component="img"
                src={about}
                alt="99gift about us image"
                sx={{
                  width: { xs: "49%", sm: "310px", md: "338px", lg: "307px" },
                  height: { xs: "170px", sm: "260px", lg: "260px" },
                  objectFit: { xs: "cover", sm: "contain", md: "cover", lg: "contain" },
                  borderRadius: { xs: "4px", sm: "10px" },
                  m: { xs: "0 3px 0 0", sm: "0 5px", lg: 0 }
                }}
              />
              <Box
                component="img"
                src={about1}
                alt="99gift about us image"
                sx={{
                  position: {
                    sm: "relative",
                    lg: "absolute"
                  },
                  width: { xs: "50%", sm: "260px", md: "330px", lg: "162px" },
                  height: { xs: "170px", sm: "260px", lg: "150px" },
                  bottom: { sm: 0, lg: "-25px" },
                  right: { sm: 0, lg: "20px" },
                  borderRadius: { xs: "4px", sm: "4px", lg: "10px" },
                  boxShadow: { xs: 0, sm: 3 },
                  mx: { sm: "5px", lg: 0 }
                }}
              />
            </Box>
          </Grid>
        </Grid>


        <Grid container sx={{
          my: { xs: 4, sm: 4, md: 4 },
          p: { xs: 0, sm: "1rem 1rem", lg: 4 },
        }}>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="secondary.main"
              sx={{
                textAlign: "center",
                fontSize: { xs: ".7rem", sm: "14px" },
                mb: { xs: 0, sm: 1 },
              }}
            >
              Best Gifting Experience You Will Receive
            </Typography>

            <Typography
              variant="h4"
              sx={{
                textTransform: "capitalize",
                fontSize: { xs: "1.4rem", sm: "22px", lg: "1.6rem", xl: "1.8rem" },
                fontWeight: { xs: 400, sm: 500 },
                mb: { xs: 1, sm: 1 },
                textAlign: "center",
              }}
            >
              99Gift is Brand Name of Zpay Solutions Pvt. Ltd.
            </Typography>
            {/* <Typography
              variant="subtitle2"
              sx={{
                mb: { xs: 1, sm: 1 },
                textAlign: "center",
                fontSize: { xs: "0.7rem", sm: "0.875rem" },
                color: { xs: "secondary.main", sm: "dark.main" },
                fontWeight: { xs: 500, sm: 500 },
                fontStyle: { xs: "italic" }
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard
            </Typography> */}
          </Grid>
          <Grid item xs={12} sx={{ my: { xs: 1, md: 1 } }}>
            <Box
              component="img"
              src={aboutVideo}
              alt="99 gift about us video"
              sx={{
                objectFit: { xs: "cover", sm: "cover" },
                width: "100%",
                height: { xs: "180px", sm: "310px", lg: "430px" },
                borderRadius: 2,
              }}
            />
          </Grid>

          <CompanyPortfolio />
        </Grid>

        {/* <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          sx={{ my: { xs: 4, sm: 4, md: 4 }, }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="secondary.main"
              sx={{
                textAlign: "center",
                fontSize: { xs: ".7rem", sm: "14px" },
                mb: { xs: 0, sm: 1 },
              }}
            >
              why choose us ?
            </Typography>

            <Typography
              variant="h4"
              color="dark.main"
              sx={{
                textTransform: "capitalize",
                fontSize: { xs: "1.4rem", sm: "22px", lg: "1.6rem", xl: "1.8rem" },
                fontWeight: { xs: 400, sm: 500 },
                mb: { xs: 1, sm: 1 },
                textAlign: "center",
              }}
            >
              Neque porro quisquam est qui dolorem ipsum
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                mb: { xs: 1, sm: 1 },
                textAlign: "center",
                fontSize: { xs: "0.7rem", sm: "0.875rem" },
                color: { xs: "secondary.main", sm: "dark.main" },
                fontWeight: { xs: 500, sm: 500 },
                fontStyle: { xs: "italic" }
              }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </Typography>
          </Grid>


          {Array.from({ length: 4 }).map((_, index) => (
            <Grid key={index * 1} item xs={12} md={6}>
              <FaqSection />
            </Grid>
          ))}

        </Grid> */}
      </Container>

    </React.Fragment>
  );
};

export default AboutPage;
