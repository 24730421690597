import React, { useState } from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuidv4 } from "uuid";

const FaqSection = (props) => {
  const { faqTitle, faqDescription } = props;
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion
      onClick={() => setExpanded(!expanded)}
      sx={{
        borderRadius: { xs: ".4rem !important", sm: "20px !important" },
        boxShadow: { xs: 0, sm: 0 },
        backgroundColor: { xs: "#dcf0f6", sm: "white.main" },
      }}
    >
      <AccordionSummary
        expandIcon={expanded ? <AddIcon /> : <RemoveIcon />}
        aria-controls={`${uuidv4()}-content`}
        id={`${uuidv4()}-header`}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: "13px", sm: "15px" },
            color: expanded ? "textPrimary.main" : "primary.main",
            fontWeight: {
              xs: expanded ? 400 : 500,
              sm: expanded ? 400 : 500,
            },
          }}
        >
          The standard Lorem Ipsum passage ?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: "12px", sm: "13px" },
            color: "textPrimary.main",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
          dolor sit, amet consectetur adipisicing elit. Laborum, quia, sed sequi
          ab eligendi iusto, inventore aperiam dicta aspernatur eveniet maiores
          reiciendis quisquam quos. Accusantium, dolorem! Adipisci, explicabo
          nisi iusto atque ad facere nobis necessitatibus, doloremque veniam
          itaque repellat praesentium delectus nemo ipsam ut recusandae quod
          architecto.
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

FaqSection.propTypes = {};

export default FaqSection;
