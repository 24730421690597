import React from "react";
import Container from "@mui/material/Container";
import Layout from "../../layouts";
import GiftCardWorking from "../DASHBOARD/GiftCardWorking";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import HeaderBanner from "../../assets/others/privacy-policy.png";
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import {
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import PageBreadcrumb from "../../components/other/PageBreadcrumb";
import { NavLink } from "react-router-dom";
import SeoMetaTag from "../../components/metaTags/SeoMetaTag";

const PrivacyPolicy = (props) => {
  const { isMobile = false } = props;
  const Mobile = useMediaQuery("(max-width:480px)");
  const backgroundColor = Mobile ? "#fff" : "white.main";
  const location = useLocation();
  const canonicalUrl = `https://www.99gift.in${location.pathname}`;
  const giftCardContiner = {
    boxShadow: { xs: 0, sm: 1, md: 2 },
    borderRadius: { xs: 0, sm: 1, md: 3 },
    p: { xs: 0, sm: 2, md: 3 },
    my: { xs: 2, sm: 3, md: 3 },
    backgroundColor: backgroundColor,
  };

  const giftCardStyle = {
    my: { xs: 0, md: 0 },
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Privacy Policy - 99Gift</title>
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content="Privacy Policy - 99Gift" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {!isMobile &&
        (
          <Container maxWidth="xl" >
            <PageBreadcrumb
              home="Home"
              currentProduct="Privacy Policy"
            />
          </Container>
        )
      }

      <Container maxWidth="false" disableGutters>
        <Box sx={{
          objectFit: "cover",
          minHeight: {
            xs: "130px",
            sm: "130px",
            md: "130px",
            lg: "216px"
          },
          display: "flex",
          justifyContent: "center"
        }}>
          <img src={HeaderBanner} alt="Header Banner" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
        </Box>
      </Container>

      <Container
        maxWidth="false"
        disableGutters={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F1F5F6",
          "@media (max-width:480px)": {
            backgroundColor: "white.main",
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          maxWidth="lg"
          sx={{
            px: { xs: 2, md: 0 },
            justifyContent: "center"
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: { xs: 2, sm: 2, md: 2 },
              mb: { xs: 1, sm: 1, md: 1 },
              background: { xs: "#c9f2ff", sm: "transparent" },
              borderRadius: { xs: 2, sm: 0 },
              boxShadow: { xs: 0, sm: 0 },
              p: { xs: 1, sm: 0 },
              justifyContent: "center",
              display: "flex",
            }}
          >
            <PrivacyPolicyTitle />
          </Grid>
          <Grid item xs={12}>
            <PrivacyPolicyUse giftCardContiner={giftCardContiner} />
            <PrivacyPolicyDisclosure giftCardContiner={giftCardContiner} />
            <PrivacyPolicyInformationSecurity
              giftCardContiner={giftCardContiner}
            />
            <PrivacyPolicyAccessAndCorrection
              giftCardContiner={giftCardContiner}
            />
            <PrivacyPolicyAnonymous giftCardContiner={giftCardContiner} />
            <PrivacyPolicyGlossary giftCardContiner={giftCardContiner} />
            <PrivacyPolicyUserAccountDeletion
              giftCardContiner={giftCardContiner}
            />
            <PrivacyPolicyOfficeDetails giftCardContiner={giftCardContiner} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicy;

function PrivacyPolicyTitle() {
  return (
    <React.Fragment>
      <Typography
        variant="body2"
        color="primary.main"
        sx={{
          fontSize: { xs: "18px", md: "18px" },
          textTransform: "capitalize",
          display: "inline-block",
        }}
      >
        Company
      </Typography>
      <Typography
        variant="body2"
        color="secondary.main"
        sx={{
          fontSize: { xs: "18px", md: "18px" },
          textTransform: "capitalize",
          display: "inline-block",
        }}
      >
        Privacy Policy
      </Typography>
    </React.Fragment>
  );
}

function PrivacyPolicyInnerTitle({ param1, param2 }) {
  return (
    <React.Fragment>
      {param1 && (
        <Typography
          variant="h3"
          color="priimary.main"
          sx={{
            display: "inline-block",
            fontSize: { xs: "20px", md: "18px" },
          }}
        >
          {param1}
        </Typography>
      )}
      {param2 && (
        <Typography
          variant="h3"
          color="secondary.main"
          sx={{
            display: "inline-block",
            ml: { xs: "2px", md: "3px" },
            fontSize: { xs: "20px", md: "18px" },
          }}
        >
          {param2}
        </Typography>
      )}
    </React.Fragment>
  );
}

function PrivacyPolicyUse({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Various options: You get to choose from over 160 options of Gift Cards. So, every shopper get almost everything according to their needs.",
    },
    {
      content:
        "Major discounts: Various types of gift cards come with discounts which reduces the load from your budget.",
    },
    {
      content:
        "Simple buying and redemption of Gift Cards: You can buy Gift cards easily in a small amount of time from our portal and also from 99gift application.",
    },
    {
      content:
        "Great substitution for Gifting: With Gift cards, you can give your loved ones something which is totally in use.Find a range of Gifting options for all occasions like Diwali, birthdays, New Year, anniversaries, etc. You can also send them instantly on Valentine's Day, Father’s Day, Mother’s Day, and other such special days.",
    },
    {
      content:
        "Great for the environment: We don’t use or issue plastic Cards that are harmful to the environment. Everything is online; which you contribute to the planet by buying a voucher from here.",
    },
    {
      content:
        "Environment friendly options: We don’t issue physical/plastic gift cards neither we encourage the use of the plastic, since it is harmful for the environment. Gift cards are online and you can contribute in Green Revolution by purchasing from here.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param2="Use" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {/* point 1 */}
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="1."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "for direct Marketing for the site 99gift might use the data mainly contact information."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
            <ListItem dense sx={{ pl: "3" }}>
              <ListItemText
                primary="i."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "Individuals can easily unsubscribe to Direct Marketing Program of 99gift."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
            <ListItem dense sx={{ pl: "3" }}>
              <ListItemText
                primary="ii."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "If the individual Opts Out of all 99gift Direct Marketing Program, the wish will be respected by 99gift and all its related subsidiaries."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
            {/* point 2 */}
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="2."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift will not use Sensitive Information for Direct Marketing."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>

            {/* point 3 */}
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="3."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift may use Personal Information if ordered by the Government of India and/or any other law enforcing agency for reasons related to law enforcement or internal investigations into unlawful activities."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>

            {/* point 4 */}
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="4."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift will not use Data where there is a doubt that the information is accurate, complete and up to date."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyDisclosure({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param2="Disclosure" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {/* point 1 */}
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="1."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "if consent has been obtained from the individual 99gift may Disclose Personal Information to related or unrelated third parties."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="2."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift may Disclose Personal Data to Related Body Corporate where they bound by the original Primary Purpose for which the information was collected."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="3."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "enable outsourcing of functions (such as billing, account management) 99gift may Disclose Personal Information to unrelated third parties to which is expected."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>

            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="4."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift may Disclose Personal Information where permitted or required by law such are law enforcement agencies, Government of India agencies, courts or external advisers."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>

            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="5."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift may Disclose Personal data for personal or public safety."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>

            <ListItem dense sx={{ pl: "0" }}>
              <ListItemText
                primary="6."
                primaryTypographyProps={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",

                  mr: { xs: "3px", md: "3px" },
                }}
                secondary={
                  "99gift does not sell or share its customer lists on a commercial basis with third parties but if it did, it would only do so only with prior consent of the individual."
                }
                secondaryTypographyProps={{
                  fontSize: { xs: "13.5px", md: "16px" },
                  color: "dark.main",
                  fontWeight: { xs: 400, md: 300 },
                  display: "inline",
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyInformationSecurity({ giftCardContiner }) {
  const orders = [
    {
      content:
        "to carry out the privacy policy in the truest possible manner 99gift directs its employees to perform duties so as to assist in every way possible.",
    },
    {
      content: `99gift will ensure that the records containing Personal Information accessible by staff who have a genuine "need to know" as well as "right to know".`,
    },
    {
      content:
        "99gift will not falsely protect any employee/ person who have used the personal data in an underhanded and/or harmful manner or are found to have tampered.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Information" param2="security" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "14.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyAccessAndCorrection({ giftCardContiner }) {
  const orders = [
    {
      content:
        "99gift will allow its records containing Personal Information to be accessed by the individual concerned in accordance with the Privacy Act.",
    },
    {
      content: `99gift will correct its records containing Personal Information as soon as practically possible, at the request of the individual concerned.`,
    },
    {
      content:
        "Individuals wishing to correct/ update/ remove their personal information due to valid reasons may do so by approaching 99gift Customer Service.",
    },
    {
      content:
        "Individuals may also update their information where individuals hold an account at 99gift.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Access and " param2="correction" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "14.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyAnonymous({ giftCardContiner }) {
  const orders = [
    {
      content:
        "99gift will not make it mandatory for visitors to its web sites to provide Personal Information unless such Personal Information is required to answer an inquiry or provide a service. However Individuals may choose to participate in providing personal information (for example, as part of a competition or questionnaire).",
    },
    {
      content: `99gift will allow its customers to transact with it anonymously wherever possible.`,
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Anonymous" param2="transactions" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "14.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyGlossary({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Collection Information means the 1.3 outlined information notified to individuals prior to, or as soon as practical after, the collection of their Personal Information.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param2="Glossary" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "14.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyUserAccountDeletion({ giftCardContiner }) {
  const orders = [
    {
      content:
        "The User has to send an email at support@99gift.in from his/her registered email ID for ID deletion request.",
    },
    {
      content:
        "The ID deletion request will be processed within 5 working days.",
    },
    {
      content:
        "After the ID deletion, User will be no longer will be able to login his/her account.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle
            param1="User Account"
            param2="Deletion Terms"
          />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "14.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyOfficeDetails({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle
            param1="99gift"
            param2="Office Information"
          />
        </Grid>

        <Grid item xs={12}>
          <List>
            {/* Address*/}
            <ListItem dense sx={{ pl: "0" }}>
              <Typography
                sx={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "primary.main",
                  fontWeight: { xs: 500, md: 500 },
                  display: "block",

                  marginRight: { xs: "3px", md: "3px" },
                }}
              >
                Address:
              </Typography>
              <NavLink
                to="https://maps.app.goo.gl/iU249fBJSpdrfwza9"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "16px",
                  color: "#00B8EE",
                  fontWeight: { xs: 500, md: 600 },
                  display: "inline-block ",
                }}
              >
                F-11,12, Agarsen Tower, Vidhyadhar Nagar
              </NavLink>
            </ListItem>

            {/* Telephone */}
            <ListItem dense sx={{ pl: "0" }}>
              <Typography
                sx={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "primary.main",
                  fontWeight: { xs: 500, md: 500 },
                  display: "block",

                  marginRight: { xs: "3px", md: "3px" },
                }}
              >
                Telephone:
              </Typography>
              <NavLink
                to="tel:+0141-3592369"
                style={{
                  fontSize: "16px",
                  color: "#00B8EE",
                  fontWeight: { xs: 500, md: 600 },
                  display: "inline-block ",
                }}
              >
                0141-3592369
              </NavLink>
            </ListItem>

            {/* Website */}
            <ListItem dense sx={{ pl: "0" }}>
              <Typography
                sx={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "primary.main",
                  fontWeight: { xs: 500, md: 500 },
                  display: "block",

                  marginRight: { xs: "3px", md: "3px" },
                }}
              >
                website:
              </Typography>
              <NavLink
                to="www.99gift.in"
                target="_blank"
                style={{
                  fontSize: "16px",
                  color: "#00B8EE",
                  fontWeight: { xs: 500, md: 600 },
                  display: "inline-block ",
                }}
              >
                www.99gift.in
              </NavLink>
            </ListItem>

            {/* Email */}
            <ListItem dense sx={{ pl: "0" }}>
              <Typography
                sx={{
                  fontSize: { xs: "14.5px", md: "16px" },
                  color: "primary.main",
                  fontWeight: { xs: 500, md: 500 },
                  display: "block",

                  marginRight: { xs: "3px", md: "3px" },
                }}
              >
                Email:
              </Typography>
              <NavLink
                to="mailto:info@99gift.in"
                style={{
                  fontSize: "16px",
                  color: "#00B8EE",
                  fontWeight: { xs: 500, md: 600 },
                  display: "inline-block ",
                }}
              >
                info@99gift.in
              </NavLink>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
