import * as React from 'react';
import { useEffect, useSelector, useDispatch } from "../../imports/generalImport";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MobileOrderCard from '../mobileComponents/mobileOrders/MobileOrderCard';
import TableFilter from "../../components/tableFilter";
import { dateSlashFormant, getLastMonthDate } from '../../shared/dateFunction';
import moment from 'moment';
import { onFetchOrder } from '../../store/userOrders/action';
import { useCallback } from 'react';
import MobileLoading from '../mobileComponents/mobileLoading/MobileLoading';
import { Helmet } from 'react-helmet-async';

const momentDate = moment();

const initialData = {
    search: null,
    filterBy: null,
    date: { from: getLastMonthDate(), to: dateSlashFormant(momentDate) },
    pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 999999,
        rowsNumber: 0,
    },
    status: 0,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function MobileOrders() {
    const [value, setValue] = React.useState(0);
    const userOrdersData = useSelector((state) => state?.UserOrder?.allData);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const filterOrdersByStatus = useCallback((status) => {
        return userOrdersData?.filter(order => order?.status?.status === status);
    }, [userOrdersData]);

    const filterOrdersWithOtherStatus = useCallback(() => {
        return userOrdersData?.filter(order => order?.status?.status !== 'Success' && order.status.status !== 'Failure');
    }, [userOrdersData]);


    useEffect(() => {
        setLoading(true);
        dispatch(onFetchOrder(initialData));
        setTimeout(() => {
            setLoading(false);
        }, 800);
    }, [dispatch]);

    return (
        <Box sx={{ width: '100%', bgcolor: '#fffbff', pt: 3, }}>
            <Helmet>
                <title>Orders Page -99gift.in</title>
                <meta property="og:title" content="Orders Page -99gift.in" />
            </Helmet>

            <TableFilter initialData={initialData} reducer={onFetchOrder} />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Success" {...a11yProps(1)} />
                    <Tab label="Failed" {...a11yProps(2)} />
                    <Tab label="Others" {...a11yProps(3)} />
                </Tabs>
            </Box>

            {loading ?
                <Box sx={{ pt: 8 }}>
                    <MobileLoading />
                </Box>
                :
                <>
                    <CustomTabPanel value={value} index={0}>
                        <MobileOrderCard ordersData={userOrdersData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <MobileOrderCard ordersData={filterOrdersByStatus('Success')} showVoucher={true} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <MobileOrderCard ordersData={filterOrdersByStatus('Failure')} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <MobileOrderCard ordersData={filterOrdersWithOtherStatus()} />
                    </CustomTabPanel>
                </>
            }

        </Box>
    );
}