import React from "react";
import Layout from "../../layouts";
import { Grid, Typography, Container, ListItemIcon } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import HeaderBanner from "../../assets/others/corp-policy.png";
import PageBreadcrumb from "../../components/other/PageBreadcrumb";
import { NavLink } from "react-router-dom";
import SeoMetaTag from "../../components/metaTags/SeoMetaTag";
const Index = (props) => {
  const { isMobile = false } = props;
  const Mobile = useMediaQuery("(max-width:480px)");
  const backgroundColor = Mobile ? "#fff" : "white.main";
  const giftCardContiner = {
    boxShadow: { xs: 0, sm: 1, md: 2 },
    borderRadius: { xs: 0, sm: 1, md: 3 },
    p: { xs: 0, sm: 2, md: 3 },
    my: { xs: 2, sm: 3, md: 3 },
    backgroundColor: backgroundColor,
  };

  return (
    <React.Fragment>
      <SeoMetaTag title={'Corp Policy | 99gift.in'} />
      {!isMobile &&
        (
          <Container maxWidth="xl">
            <PageBreadcrumb
              home="Home"
              currentProduct="Corp Policy"
            />
          </Container>
        )
      }


      <Container maxWidth="false" disableGutters>
        <Box sx={{ objectFit: "cover", minHeight: { sm: "216px" }, display: "flex", justifyContent: "center" }}>
          <img src={HeaderBanner} alt="Header Banner"
            style={{ objectFit: "contain", width: "100%", height: "auto" }} />
        </Box>
      </Container>

      <Container
        maxWidth="false"
        disableGutters={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F1F5F6",
          "@media (max-width:480px)": {
            backgroundColor: "white.main",
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          maxWidth="lg"
          sx={{
            px: { xs: 2, md: 0 },
            justifyContent: "center"
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: { xs: 2, sm: 2, md: 2 },
              mb: { xs: 1, sm: 2, md: 2 },
              background: { xs: "#c9f2ff", sm: "transparent" },
              borderRadius: { xs: 2, sm: 0 },
              boxShadow: { xs: 0, sm: 0 },
              p: { xs: 1, sm: 0 },
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CorpPolicyTitle />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="primary.main"
              sx={{
                fontSize: { xs: "14.5px", md: "14px" },
                fontWeight: { xs: 400, lg: 500 },
                textAlign: { xs: "left", sm: "center" },
              }}
            >
              Thank you for choosing Zpay-Corp as your trusted e-gifting
              platform. At Zpay-Corp, we are committed to safeguarding the
              privacy and confidentiality of the information you share with us.
              This Privacy Policy outlines how we collect, use, disclose, and
              protect your information when you engage with our corporate
              services.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CorpPolicyCollectionInformation giftCardContiner={giftCardContiner} />
            <CorpPolicyInformation giftCardContiner={giftCardContiner} />
            <CorpPolicyDataSecurity giftCardContiner={giftCardContiner} />
            <CorpPolicyThirdPartyDisclosure giftCardContiner={giftCardContiner} />
            <CorpPolicyRetention giftCardContiner={giftCardContiner} />
            <CorpPolicyChoice giftCardContiner={giftCardContiner} />
            <CorpPolicyChangePrivacyPolicy giftCardContiner={giftCardContiner} />
            <CorpPolicyContactUs giftCardContiner={giftCardContiner} />
            <CorpPolicyUserAccountDeletionTerms giftCardContiner={giftCardContiner} />
            <PrivacyPolicyOfficeDetails giftCardContiner={giftCardContiner} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;

function CorpPolicyTitle() {
  return (
    <React.Fragment>
      <Typography
        variant="body2"
        color="primary.main"
        sx={{
          fontSize: { xs: "18px", md: "18px" },
          textTransform: "capitalize",
          display: "inline-block",
          textAlign: "center",
        }}
      >
        Corp Privacy
      </Typography>
      <Typography
        variant="body2"
        color="secondary.main"
        sx={{
          fontSize: { xs: "18px", md: "18px" },
          textTransform: "capitalize",
          display: "inline-block",
          textAlign: "center",
        }}
      >
        Policy
      </Typography>
    </React.Fragment>
  );
}

function PrivacyPolicyInnerTitle({ param1, param2 }) {
  return (
    <React.Fragment>
      {param1 && (
        <Typography
          variant="h3"
          color="priimary.main"
          sx={{
            display: "inline-block",
            fontSize: { xs: "14.5px", md: "18px" },
          }}
        >
          {param1}
        </Typography>
      )}
      {param2 && (
        <Typography
          variant="h3"
          color="secondary.main"
          sx={{
            display: "inline-block",
            ml: { xs: "2px", md: "3px" },
            fontSize: { xs: "14.5px", md: "18px" },
          }}
        >
          {param2}
        </Typography>
      )}
    </React.Fragment>
  );
}

function CorpPolicyCollectionInformation({ giftCardContiner }) {
  const orders = [
    {
      content: "Contact information of authorized representatives.",
    },
    {
      content: "Company details for service customization.",
    },
    {
      content: "Transaction and billing information for accounting purposes.",
    },
  ];

  return (
    <Grid
      container
      sx={{
        ...giftCardContiner,
      }}
    >
      <Grid item xs={12}>
        <PrivacyPolicyInnerTitle
          param1="Collection of"
          param2="Information"
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
        <Typography
          variant="subtitle2"
          color="primary.main"
          sx={{
            fontSize: { xs: "13.5px", md: "16px" },
            color: "primary.main",
            fontWeight: { xs: 400, md: 400 },
            display: "inline",
          }}
        >
          We collect information necessary for providing our e-gifting
          services to your organization. This information may include, but is
          not limited to :
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List>
          {orders.map((order) => (
            <ListItem key={`orders-${order?.id}`} dense sx={{ pl: "0" }}>
              {order?.icon && (
                <ListItemIcon sx={{ color: "inherit" }}>
                  {order?.icon}
                </ListItemIcon>
              )}
              {order?.content && (
                <ListItemText
                  primary="&#9679;"
                  primaryTypographyProps={{
                    fontSize: { xs: "13.5px", md: "16px" },
                    color: "secondary.main",
                    fontWeight: { xs: 400, md: 300 },
                    display: "inline",

                    mr: { xs: "3px", md: "3px" },
                  }}
                  secondary={order?.content}
                  secondaryTypographyProps={{
                    fontSize: { xs: "13.5px", md: "16px" },
                    color: "primary.main",
                    fontWeight: { xs: 400, md: 300 },
                    display: "inline",
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

function CorpPolicyInformation({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Customizing our e-gifting services to meet your organization's needs.",
    },
    {
      content:
        "Processing transactions and invoices related to your corporate account.",
    },
    {
      content:
        "Communicating with authorized representatives regarding service updates, promotions, or other relevant information.",
    },
  ];
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Use of" param2="Information" />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            We use the collected information for the following purposes:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary="&#9679;"
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "secondary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "primary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyDataSecurity({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Customizing our e-gifting services to meet your organization's needs.",
    },
    {
      content:
        "Processing transactions and invoices related to your corporate account.",
    },
    {
      content:
        "Communicating with authorized representatives regarding service updates, promotions, or other relevant information.",
    },
  ];
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Data" param2="Security" />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            We employ industry-standard security measures to protect your
            information from unauthorized access, disclosure, alteration, and
            destruction. Our security practices include:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary="&#9679;"
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "secondary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "primary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyThirdPartyDisclosure({ giftCardContiner }) {
  const orders = [
    {
      content:
        "To our trusted service providers who assist us in delivering our services (e.g., billing or customer support).",
    },
    {
      content:
        "When required by law, such as in response to a subpoena, court order, or other legal process.",
    },
    {
      content:
        "In the event of a merger, acquisition, or sale of all or a portion of our assets, where your information may be transferred as part of the transaction.",
    },
  ];
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Third-Party" param2="Disclosures" />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            We do not sell or share your corporate information with third
            parties for commercial purposes. However, certain disclosures may
            occur in the following circumstances:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary="&#9679;"
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "secondary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "primary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyRetention({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Retention of" param2="Information" />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            We retain your corporate information for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy, unless a
            longer retention period is required or permitted by law.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyChoice({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Opting out of promotional communications by contacting our customer support.",
    },
    {
      content:
        "Updating your contact and company information through our customer support.",
    },
  ];
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Third-Party" param2="Disclosures" />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            As a corporate partner, you have the following choices:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={order.id} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary="&#9679;"
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "secondary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "primary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyChangePrivacyPolicy({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle
            param1="Changes to the"
            param2="Privacy Policy"
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            We may update this Privacy Policy from time to time to reflect
            changes in our practices. We will notify you of any significant
            changes by posting the updated policy on our website.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyContactUs({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle param1="Contact" param2="Us" />
        </Grid>
        <Grid item xs={12} sx={{ mt: { xs: "5px", sm: "5px" } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{
              fontSize: { xs: "13.5px", md: "16px" },
              color: "primary.main",
              fontWeight: { xs: 400, md: 400 },
              display: "inline",
            }}
          >
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please contact our privacy team at
            support@99gift.in. By engaging with our corporate services, you
            agree to the terms outlined in this Privacy Policy. Thank you for
            trusting Zpay-Corp with your corporate gifting needs.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function CorpPolicyUserAccountDeletionTerms({ giftCardContiner }) {
  const orders = [
    {
      content:
        "The User has to send an email at support@99gift.in from his/her registered email ID for ID deletion request.",
    },
    {
      content:
        "The ID deletion request will be processed within 5 working days.",
    },
    {
      content:
        "After the ID deletion, User will be no longer will be able to login his/her account.",
    },
  ];
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <PrivacyPolicyInnerTitle
            param1="User Account"
            param2="Deletion Terms"
          />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order) => (
              <ListItem key={`order-id-${order.id}`} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary="&#9679;"
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "secondary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "primary.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PrivacyPolicyOfficeDetails({ giftCardContiner }) {
  return (
    <Grid
      container
      sx={{
        ...giftCardContiner,
      }}
    >
      <Grid item xs={12}>
        <PrivacyPolicyInnerTitle
          param1="99gift"
          param2="Office Information"
        />
      </Grid>

      <Grid item xs={12}>
        <List>
          {/* Address*/}
          <ListItem dense sx={{ pl: "0" }}>
            <Typography
              sx={{
                fontSize: { xs: "13.5px", md: "16px" },
                color: "primary.main",
                fontWeight: { xs: 500, md: 500 },
                display: "block",

                marginRight: { xs: "3px", md: "3px" },
              }}
            >
              Address:
            </Typography>
            <NavLink
              to="https://maps.app.goo.gl/iU249fBJSpdrfwza9"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "16px",
                color: "#00B8EE",
                fontWeight: { xs: 500, md: 600 },
                display: "inline-block ",
              }}
            >
              F-11,12, Agarsen Tower, Vidhyadhar Nagar</NavLink>
          </ListItem>

          {/* Telephone */}
          <ListItem dense sx={{ pl: "0" }}>
            <Typography
              sx={{
                fontSize: { xs: "13.5px", md: "16px" },
                color: "primary.main",
                fontWeight: { xs: 500, md: 500 },
                display: "block",

                marginRight: { xs: "3px", md: "3px" },
              }}
            >
              Telephone:
            </Typography>
            <NavLink
              to="tel:+0141-3592369"
              style={{
                fontSize: "16px",
                color: "#00B8EE",
                fontWeight: { xs: 500, md: 600 },
                display: "inline-block ",
              }}
            >
              0141-3592369
            </NavLink>
          </ListItem>

          {/* Website */}
          <ListItem dense sx={{ pl: "0" }}>
            <Typography
              sx={{
                fontSize: { xs: "13.5px", md: "16px" },
                color: "primary.main",
                fontWeight: { xs: 500, md: 500 },
                display: "block",

                marginRight: { xs: "3px", md: "3px" },
              }}
            >
              website:
            </Typography>
            <NavLink
              to="https://99gift.in/"
              target="_blank"
              style={{
                fontSize: "16px",
                color: "#00B8EE",
                fontWeight: { xs: 500, md: 600 },
                display: "inline-block ",
              }}
            >
              www.99gift.in
            </NavLink>
          </ListItem>

          {/* Email */}
          <ListItem dense sx={{ pl: "0" }}>
            <Typography
              sx={{
                fontSize: { xs: "13.5px", md: "16px" },
                color: "primary.main",
                fontWeight: { xs: 500, md: 500 },
                display: "block",

                marginRight: { xs: "3px", md: "3px" },
              }}
            >
              Email:
            </Typography>
            <NavLink
              to="mailto:info@99gift.in"
              style={{
                fontSize: "16px",
                color: "#00B8EE",
                fontWeight: { xs: 500, md: 600 },
                display: "inline-block ",
              }}
            >
              info@99gift.in
            </NavLink>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
