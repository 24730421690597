import React, { useEffect } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Typography, Box } from "@mui/material";
import { handleAmount } from "../../shared/extra";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBalance } from "../../store/userBalance/action"

const UserBalance = () => {
  const balance = useSelector((state) => state.UserBalance.allData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserBalance())
  }, []);


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        userSelect: "none",
        minWidth: "50px",
      }}
    >
      <AccountBalanceWalletIcon size="large" sx={{ mr: "3px", color: "textPrimary.main1" }} />
      <Typography
        component="span"
        variant="subtitle1"
        gutterBottom
        sx={{
          mb: 0, mx: "4px", color: "textPrimary.main1", fontWeight: 400, fontSize: {
            xs: "15px",
            lg: "15.5px",
            xl: "16.5px"
          }

        }}
      >
        &#8377;{balance > 1 ? handleAmount(balance) : "0.00"}
      </Typography>
    </Box>
  );

};

export default UserBalance;
