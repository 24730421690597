import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Snowfall from 'react-snowfall';
import Snowflakes from 'magic-snowflakes';

function ChristmasAnimation({ children }) {
    // useEffect(() => {
    //     const snowflakes = new Snowflakes({
    //         color: '#fff', // Red snowflakes
    //         container: document.querySelector('#snowflakes-container'), // Snowflakes container
    //         count: 1300, // Number of snowflakes
    //         minOpacity: 0.1,
    //         maxOpacity: 0.95,
    //         minSize: 20,
    //         maxSize: 50,
    //         rotation: true,
    //         speed: 1,
    //         wind: true, // Enable wind effect
    //         width: 500,
    //         height: 9000,
    //         zIndex: 100,
    //         autoResize: true,
    //     });

    //     // Clean up on unmount
    //     return () => snowflakes.destroy();
    // }, []);


    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                minHeight: 400,
            }}
        >
            {children}

            {/* Container for magic-snowflakes */}
            {/* <div id="snowflakes-container"></div> */}

            {/* React-Snowfall */}
            <Snowfall
                color="white"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
                snowflakeCount={1300}
            >
            </Snowfall>
        </Box>
    );
}

export default ChristmasAnimation;
