import React from 'react'
import { Box } from '../../../imports/muiImport';
import { christmasMobileHeaderBell3 } from "../../../imports/generalImport";

const DesktopHeaderBell = () => {
    return (
        <Box
            component={'div'}
            sx={{
                position: 'relative',
                top: 0,
                height: 50,
                backgroundColor: "white.main",
            }}
        >
            <Box
                sx={{
                    height: 76,
                    width: "100%",
                    position: "fixed",
                    zIndex: 9,
                    top: -23,
                    backgroundImage: `url(${christmasMobileHeaderBell3})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    // backgroundColor: "white.main",
                    backgroundColor: "transparent",
                }}
            />

        </Box>
    )
}

export default DesktopHeaderBell