import React, { useEffect, useState, useRef } from 'react';
import { christmasSantaTuneAudio1 } from '../../../imports/generalImport';
import { Box, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const ChristmasSantaAudio = () => {
    // Changed initial state to false since autoplay is not allowed without user interaction
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        // Initialize the audio without autoplay
        const audio = new Audio(christmasSantaTuneAudio1);
        audioRef.current = audio;
        
        // Add ended event listener to update play state when song completes
        audio.addEventListener('ended', () => {
            setIsPlaying(false);
        });
        
        // Cleanup on unmount
        return () => {
            audio.removeEventListener('ended', () => {
                setIsPlaying(false);
            });
            audio.pause();
            audio.currentTime = 20;
        };
    }, []);

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                // Unmute and play on user click
                audioRef.current.muted = false;
                audioRef.current.play().catch(error => {
                    console.log("Audio playback failed:", error);
                });
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: {
                    xs: '35px',
                    md: '200px'
                },
                right: {
                    xs: '-10px',
                    md: '-10px'
                },
                zIndex: {
                    xs: 9991,
                    md: 1000
                },
                backgroundColor: 'primaryDark.main',
                borderRadius: '10px 0px 0 10px',
                width: {
                    xs: 80,
                    md:100
                },
                height: {
                    xs: '40px',
                    md: '50px'
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                opacity: {
                    xs: 0.9,
                    md: 0.7
                },
                "&:hover": {
                    opacity: 1
                }
            }}
        >
            <IconButton
                color="white"
                onClick={handlePlayPause}
                sx={{
                    color: 'white.main',
                    fontSize: '13.5px',
                }}
            >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                {isPlaying ? 'Pause' : 'Play'}
            </IconButton>
        </Box>
    );
};

export default ChristmasSantaAudio;
