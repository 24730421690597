import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Drawer, Box, Avatar, Typography } from "../../../imports/muiImport";
import { React, useState, defaultProductImage, useSelector, useNavigate, Fragment, christmasSantaCap1 } from '../../../imports/generalImport';
import { categoryUrl, handleAmount } from '../../../shared/extra';
import MobileNavbar from './MobileNavbar';
import HeroSlider from "../../../components/homeSlider";
import { useAuth } from '../../../hooks/contexts/AuthContext';
import MobileLogoutButton from '../mobileLogoutButton/MobileLogoutButton';
import CloseIcon from '@mui/icons-material/Close';

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "primary.main",
        },
        children: `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
    };
}

function MobileHeaderDrawer(props) {
    const { isPageTitleUpdate, pageTitle } = props;
    const [open, setOpen] = useState(false);
    const categoryData = useSelector((state) => state.Category.allData);
    const UserInfomation = useSelector((state) => state.UserProfile?.allData);
    const navigate = useNavigate();
    const {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    } = useAuth();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{
            minWidth: 230,
            // maxWidth: 230,
            padding: "0 16px",
        }} role="presentation" onClick={toggleDrawer(false)}>
            <List sx={{
                width: '100%',
                bgcolor: '#EEF7FF',
                borderRadius: 2,
                border: "1px dashed #52b9d7",
                p: ".4px",
            }}>
                {categoryData?.map((item, index) => (
                    <Fragment key={`category-${item?.id * 1}`}>
                        <ListItem disablePadding
                            onClick={() => redirectToUser(`card-list?category=${categoryUrl(item?.title)}`)}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <Box
                                        component="img"
                                        src={item?.image || defaultProductImage}
                                        width={42}
                                        height={42}
                                        sx={{
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                            p: "4px",
                                            border: "1px solid #deebff",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={item?.title}
                                    primaryTypographyProps={{
                                        sx: {
                                            color: 'primary.main',
                                            fontWeight: 400,
                                            fontSize: "3.4vw"
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>

                        {index < categoryData?.length - 1 && (
                            <Divider variant="inset" component="li" />
                        )}
                    </Fragment>
                ))}
            </List>

            <Box sx={{
                mt: 2,
            }}>
                <List sx={{
                    width: '100%',
                    bgcolor: '#EEF7FF',
                    borderRadius: 2,
                    border: "1px dashed #52b9d7",
                    p: ".4px",
                }}>

                    {usefulLinks?.map((item, index) => (
                        <Fragment key={`other-page-${index * 1}`}>
                            <ListItem disablePadding
                                onClick={() => redirectToUser(item?.link)}
                            >
                                <ListItemButton>
                                    <ListItemText primary={item?.title}
                                        primaryTypographyProps={{
                                            sx: {
                                                color: 'primary.main',
                                                fontWeight: 400,
                                                fontSize: "3.4vw"
                                            }
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </Fragment>
                    ))}

                </List>
            </Box>

        </Box>
    );

    return (
        <>
            <MobileNavbar toggleDrawer={toggleDrawer} isPageTitleUpdate={isPageTitleUpdate} pageTitle={pageTitle} />
            <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        minHeight: '100%',
                        overflowY: 'auto',
                    }
                }}>

                <Box sx={{
                    my: 1,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: '95%',
                    mt: 4,
                }}>
                    <CloseIcon fontSize="large" mr={2}
                        onClick={toggleDrawer(false)}
                    />
                </Box>

                <Box sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: '100%',
                    mb: 4,
                }}>
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <Avatar
                            {...(isLoggedIn && UserInfomation?.data?.name
                                ? stringAvatar(UserInfomation?.data?.name)
                                : "Guest"
                            )}
                            sx={{
                                width: 72,
                                height: 72,
                                bgcolor: "secondary.main",
                                boxShadow: 4,
                                fontSize: "18.5px",
                                mb: 2,
                                textTransform: "capitalize",
                            }}
                        />

                        <Box
                            component={'img'}
                            src={christmasSantaCap1}
                            sx={{
                                width: '119px', // Adjust the size of the cap
                                height: '100px', // Adjust the size of the cap
                                position: 'absolute',
                                top: '-44px', // Adjust the vertical position
                                right: '-41px', // Adjust the horizontal position
                                transform: 'rotate(-15deg)', // Add rotation for a more natural look
                            }}
                        />
                    </Box>

                    <Typography variant='subtitle2' sx={{ color: "primary.main", fontSize: "11.5px", textTransform: "capitalize" }}>
                        {isLoggedIn ? UserInfomation?.data?.name : "Guest"}
                    </Typography>

                    {isLoggedIn &&
                        (<Fragment>
                            <Typography variant='body1' sx={{ color: "textPrimary.main", fontSize: "10.5px", textTransform: "lowercase" }}>{UserInfomation?.data?.email}</Typography>
                            <Typography variant='subtitle2' sx={{ color: "primary.main", fontSize: "12.5px" }}>Balance:  ₹{handleAmount(UserInfomation?.data?.balance)}</Typography>
                            <Typography variant='subtitle2' sx={{ color: "primary.main", fontSize: "12.5px" }}>Lifetime Saving:  ₹{handleAmount(UserInfomation?.data?.savings || 0)}</Typography>
                        </Fragment>)
                    }

                    <Box sx={{
                        mt: 1,
                        maxWidth: 270,
                        maxHeight: 118,
                        p: 2,
                        mb: 4,
                    }}>
                        <HeroSlider dots={true} height={118} />
                        <Divider />
                    </Box>

                    <Box sx={{
                        width: '100%',
                        mb: 1,
                    }}>
                        {DrawerList}
                    </Box>




                    {isLoggedIn &&
                        (
                            <>
                                <Box sx={{
                                    width: '100%',
                                    mb: 1,
                                }}>
                                    <Divider />
                                </Box>

                                <Box sx={{ width: '90%' }}>
                                    <MobileLogoutButton py={1.8} />
                                </Box>
                            </>
                        )
                    }
                </Box>
            </Drawer>
        </>
    )

    function redirectToUser(url) {
        navigate(`/${url}`);
        toggleDrawer(false);
    }
}

const usefulLinks = [
    { title: "Blogs", link: "blog" },
    { title: "Corporate", link: "corporates" },
    { title: "About Us", link: "aboutus" },
    { title: "Contact Us", link: "contact-us" },
    { title: "How Gift Voucher Works", link: "how-gift-card-work" },
    { title: "Crop Policy", link: "corppolicy" },
    { title: "Privacy Policy", link: "privacypolicy" },
    { title: "Terms and Conditions", link: "termsandcondition" },
];

export default MobileHeaderDrawer;